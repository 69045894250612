export class AddToCart extends HTMLElement {
  constructor() {
    super();
    this.glueVariantId = '46186342482137';
    this.init();
  }

  init() {
    const form = this.querySelector('form');
    const checkbox = document.querySelector('#add-glue-checkbox');

    form.addEventListener('submit', (e) => this.handleSubmit(e, form, checkbox));
  }

  handleSubmit(e, form, checkbox) {
    e.preventDefault();

    const formData = new FormData(form);
    const addGlueChecked = !!checkbox?.checked;
    const selectedVariantId = formData.get('id');
    const quantity = formData.get('quantity');

    const dimensions = form.querySelector('#total-price-dimensions')?.value;
    const productCode = form.querySelector('input[name="properties[_product_code]"]')?.value;
    const height = form.querySelector('#total-price-dimensions-height')?.value;
    const width = form.querySelector('#total-price-dimensions-width')?.value;

    const items = [
      {
        id: selectedVariantId,
        quantity: quantity,
        properties: {
          Dimenzije: dimensions,
          _product_code: productCode,
          _height: height,
          _width: width,
        },
      },
    ];

    if (addGlueChecked) {
      items.push({
        id: this.glueVariantId,
        quantity: 1,
      });
    }

    fetch('/cart/add.js', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ items: items }),
    })
      .then((response) => response.json())
      .then((data) => {
        const firstStatusLetter = data?.status?.toString()[0];
        const isError = firstStatusLetter == '4' || firstStatusLetter == '5';
        if (isError) {
          console.error('Error adding items to cart:', data);
          return;
        }
        window.location.href = '/cart';
      })
      .catch((error) => {
        console.error('Error adding items to cart:', error);
      });
  }
}
