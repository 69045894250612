'use-strict';

import { trapFocus } from './global.js';
import { root, rootAction } from './root.js';
import DetailsDisclosure from './details-disclosure';
import BaseSlide from './base-slide.js';
import SlideSection from './slide-section.js';
import CountdownTimer from './countdown-timer.js';
import Close from './close-button.js';
import Overlay from './overlay-opacity';
import ToggleNav from './toggle-nav.js';
// import StickyHeader from './sticky-header.js';
import BackBtn from './back-button.js';
import ToggleMenu from './toggle-menu.js';
import AgeVerifier from './age-verifier.js';
import BackToTop from './back-to-top.js';
import InventoryProgressBar from './inventory-progress-bar.js';
import FSProgressBar from './free-ship-progress-bar.js';
import ModalPopup from './modal-popup.js';
import NavBar from './mobile-navigation-bar';
import ProgressBar from './progress-bar.js';
import CollapsibleSection from './collapsible-block.js';
import Quickview from './button-quickview.js';
import QuickbuyCanvas from './quickbuy-canvas.js';
import { AddToCart } from './add-to-cart.js';

const components = [
  { name: 'base-slide', class: BaseSlide },
  { name: 'slide-section', class: SlideSection },
  { name: 'countdown-timer', class: CountdownTimer },
  { name: 'close-button', class: Close },
  { name: 'overlay-opacity', class: Overlay },
  { name: 'toggle-nav', class: ToggleNav },
  // { name: 'sticky-header', class: StickyHeader },
  { name: 'back-button', class: BackBtn },
  { name: 'toggle-menu', class: ToggleMenu },
  { name: 'age-verifier', class: AgeVerifier },
  { name: 'back-to-top', class: BackToTop }, //
  { name: 'inventory-progress-bar', class: InventoryProgressBar },
  { name: 'free-ship-progress-bar', class: FSProgressBar },
  { name: 'modal-popup', class: ModalPopup },
  { name: 'mobile-navigation-bar', class: NavBar },
  { name: 'progress-bar', class: ProgressBar },
  { name: 'collapsible-block', class: CollapsibleSection },
  { name: 'button-quickview', class: Quickview },
  { name: 'quickbuy-canvas', class: QuickbuyCanvas },
  { name: 'add-to-cart', class: AddToCart },
];

components.forEach(({ name, class: componentClass }) => {
  try {
    if (!customElements.get(name)) {
      customElements.define(name, componentClass);
    }
  } catch (error) {
    console.error('Custom element: ', error, name);
  }
});

var Shopify = Shopify || {};

let parser = new DOMParser();

export const slideAnime = (() => {
  let isAnimating = false;

  return (setOptions) => {
    const defaultOptions = {
      target: false,
      animeType: 'slideToggle',
      duration: 250,
      easing: 'ease',
      isDisplayStyle: 'block',
      parent: false,
    };
    const options = Object.assign({}, defaultOptions, setOptions);
    const target = options.target;
    const parent = options.parent;
    if (!target) {
      return;
    }

    if (isAnimating) {
      return;
    }
    isAnimating = true;
    parent.classList?.toggle('opened');

    let animeType = options.animeType;
    const styles = getComputedStyle(target);
    if (animeType === 'slideToggle') {
      animeType = styles.display === 'none' ? 'slideDown' : 'slideUp';
    }
    if (
      (animeType === 'slideUp' && styles.display === 'none') ||
      (animeType === 'slideDown' && styles.display !== 'none') ||
      (animeType !== 'slideUp' && animeType !== 'slideDown')
    ) {
      isAnimating = false;
      return false;
    }
    target.style.overflow = 'hidden';
    const duration = options.duration;
    const easing = options.easing;
    const isDisplayStyle = options.isDisplayStyle;

    if (animeType === 'slideDown') {
      target.style.display = isDisplayStyle;
    }
    const heightVal = {
      height: target.getBoundingClientRect().height + 'px',
      marginTop: styles.marginTop,
      marginBottom: styles.marginBottom,
      paddingTop: styles.paddingTop,
      paddingBottom: styles.paddingBottom,
    };

    Object.keys(heightVal).forEach((key) => {
      if (parseFloat(heightVal[key]) === 0) {
        delete heightVal[key];
      }
    });
    if (Object.keys(heightVal).length === 0) {
      isAnimating = false;
      return false;
    }
    let slideAnime;
    if (animeType === 'slideDown') {
      Object.keys(heightVal).forEach((key) => {
        target.style[key] = 0;
      });
      slideAnime = target.animate(heightVal, {
        duration: duration,
        easing: easing,
      });
    } else if (animeType === 'slideUp') {
      Object.keys(heightVal).forEach((key) => {
        target.style[key] = heightVal[key];
        heightVal[key] = 0;
      });
      slideAnime = target.animate(heightVal, {
        duration: duration,
        easing: easing,
      });
    }
    slideAnime.finished.then(() => {
      target.style.overflow = '';
      Object.keys(heightVal).forEach((key) => {
        target.style[key] = '';
      });
      if (animeType === 'slideUp') {
        target.style.display = 'none';
      }
      isAnimating = false;
    });
  };
})();

window.Shopify.formatMoney = function (cents, format) {
  if (typeof cents == 'string') {
    cents = cents.replace('.', '');
  }
  var value = '';
  var placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
  var formatString = format || this.money_format;
  function defaultOption(opt, def) {
    return typeof opt == 'undefined' ? def : opt;
  }

  function formatWithDelimiters(number, precision, thousands, decimal) {
    precision = defaultOption(precision, 2);
    thousands = defaultOption(thousands, ',');
    decimal = defaultOption(decimal, '.');

    if (isNaN(number) || number == null) {
      return 0;
    }

    number = (number / 100.0).toFixed(precision);

    var parts = number.split('.'),
      dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousands),
      cents = parts[1] ? decimal + parts[1] : '';

    return dollars + cents;
  }

  switch (formatString.match(placeholderRegex)[1]) {
    case 'amount':
      value = formatWithDelimiters(cents, 2);
      break;
    case 'amount_no_decimals':
      value = formatWithDelimiters(cents, 0);
      break;
    case 'amount_with_comma_separator':
      value = formatWithDelimiters(cents, 2, '.', ',');
      break;
    case 'amount_no_decimals_with_comma_separator':
      value = formatWithDelimiters(cents, 0, '.', ',');
      break;
  }
  return formatString.replace(placeholderRegex, value);
};
export function checkDeviceIsMobile() {
  const userAgent = navigator.userAgent.toLowerCase();
  const mobileKeywords = ['android', 'iphone', 'ipod', 'ipad', 'windows phone'];
  for (let keyword of mobileKeywords) {
    if (userAgent.indexOf(keyword) > -1) {
      return true;
    }
  }
  return false;
}
let windowWidth = window.innerWidth;

function windowResize() {
  window.addEventListener('resize', function () {
    windowWidth = window.innerWidth;
    if (windowWidth > 1024) {
      const hasOpens = document.querySelectorAll('.has-open');
      if (hasOpens.length != 0) {
        hasOpens.forEach((e) => {
          e.setAttribute('open', '');
        });
      }
    }
  });
}
windowResize();

function pauseAllMedia(s) {
  if (!s) return;

  s.querySelectorAll('.js-youtube').forEach((video) => {
    if (!video.paused) {
      video.contentWindow.postMessage(
        '{"event":"command","func":"' + 'pauseVideo' + '","args":""}',
        '*'
      );
    }
  });
  s.querySelectorAll('.js-vimeo').forEach((video) => {
    if (video.contentWindow && video.contentWindow.postMessage) {
      video.contentWindow.postMessage('{"method":"pause"}', '*');
    }
  });
  s.querySelectorAll('video').forEach((video) => {
    if (!video.paused) {
      video.pause();
    }
  });
}

window.pauseAllMedia = pauseAllMedia;

export var responsiveImage = (function () {
  return {
    init: function () {
      const img = document.querySelectorAll("img[data-sizes='auto']");
      if (img.length != 0) {
        img.forEach((e) => {
          if (e.offsetWidth != 0) {
            e.setAttribute('sizes', e.offsetWidth + 'px');
          }
        });
      }
    },
  };
})();
responsiveImage.init();

export var removeIdSvg = (function () {
  return {
    onLoading: function () {
      const svg = document.querySelectorAll('svg.img-placeholder');
      if (svg.length != 0) {
        svg.forEach((e) => {
          const defs = e.querySelector('defs');
          if (defs) {
            defs.parentNode.removeChild(defs);
          }
          const g_tag = e.querySelector('g');
          if (defs) {
            g_tag.removeAttribute('clip-path');
          }
        });
      }
    },
  };
})();
removeIdSvg.onLoading();

export var closePopup = (function () {
  return {
    hide: function () {
      rootAction.remove();
      const os =
        document.querySelector('.overlay-section .bls__canvas.active') ||
        document.querySelector('.overlay-section .bls__canvas-mb.active');
      if (!os) return;
      os.classList.remove('active');
      const fi = os.dataset?.focusItem;
      if (fi && document.querySelector('#' + fi)) {
        removeTrapFocus(document.querySelector('#' + fi));
      }
    },
    onEscKeyUp: function () {
      document.addEventListener('keyup', (event) => {
        if (event.code?.toUpperCase() === 'ESCAPE') this.hide();
      });
    },
  };
})();

// export var getHeightHeader = (function () {
//   return {
//     init: function () {
//       const _this = this;
//       window.addEventListener('resize', function () {
//         _this.getHeight();
//       });
//       _this.getHeight();
//     },
//     getHeight() {
//       if (!document.querySelector('.section-header header')) return;
//       var rect = document.querySelector('.section-header header').getBoundingClientRect();
//       var divTop = 0;
//       if (window.scrollY == 0) {
//         divTop = rect.top + document.querySelector('.section-header header').offsetHeight;
//       } else {
//         if (rect.top > 0) {
//           divTop = rect.top + document.querySelector('.section-header header').offsetHeight;
//         } else {
//           divTop = document.querySelector('.section-header header').offsetHeight;
//         }
//       }
//       const sectionHeader = document.querySelector('.section-header');
//       const sectionAB = document.querySelector('.section-announcement-bar');
//       if (this.checkPositionAnnouncementBar(sectionHeader, sectionAB)) {
//         const ab = sectionAB.querySelector('announcement-bar');
//         if (ab) {
//           divTop += ab.offsetHeight;
//         }
//       }
//       root.style.setProperty('--header-height', divTop + 'px');
//     },

//     checkPositionAnnouncementBar(sectionHeader, sectionAB) {
//       if (sectionHeader && sectionAB) {
//         const rectA = sectionHeader.getBoundingClientRect();
//         const rectB = sectionAB.getBoundingClientRect();

//         const isAOnTopOfB = rectA.bottom <= rectB.top;

//         if (isAOnTopOfB) {
//           return true;
//         } else {
//           return false;
//         }
//       }
//     },
//   };
// })();
// getHeightHeader.init();

export var getScrollBarWidth = (function () {
  return {
    init: function () {
      var scrollDiv = document.createElement('div');
      scrollDiv.style.width = '100px';
      scrollDiv.style.height = '100px';
      scrollDiv.style.overflow = 'scroll';
      scrollDiv.style.position = 'absolute';
      scrollDiv.style.top = '-9999px';
      document.body.appendChild(scrollDiv);
      var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);
      return scrollbarWidth;
    },
  };
})();

class MainMenu extends HTMLElement {
  constructor() {
    super();
    this.details = this.querySelectorAll('details[id]');
    this.windowWidth = window.innerWidth;
    if (this.details.length == 0) return;
    this.onClick();
  }

  onResize() {
    const _this = this;
    document.addEventListener('resize', () => {
      _this.windowWidth = window.innerWidth;
    });
  }

  onClick() {
    const _this = this;
    this.details.forEach((e) =>
      e.addEventListener(
        'click',
        function () {
          if (window.innerWidth <= 1024) {
            trapFocus(e.querySelector('.sub-menu'));
            this.classList.add('menu-opening');
            const ul = _this.querySelector('ul.parent-menu');
            if (ul) {
              ul.classList.add('submenu-open');
            }
          } else {
            this.classList.remove('menu-opening');
            const ul = _this.querySelector('ul.parent-menu');
            if (ul) {
              ul.classList.remove('submenu-open');
            }
          }
        },
        false
      )
    );
  }

  close(focusToggle = true) {
    removeTrapFocus();
    root.classList.remove('open-nav');
  }
}

if (!customElements.get('main-menu')) {
  customElements.define('main-menu', MainMenu);
}

class HeaderMenu extends DetailsDisclosure {
  constructor() {
    super();
    this.onResizeHandler = this.onResize.bind(this);
    this.onInit();
    window.addEventListener('resize', this.onResizeHandler);
  }

  onResize() {
    this.onInit();
  }

  disconnectedCallback() {
    window.removeEventListener('resize', this.onResizeHandler);
  }

  onInit() {
    if (window.innerWidth > 1024) {
      document.addEventListener('focusin', this.onFocusIn.bind(this));
    } else {
      document.removeEventListener('focusin', this.onFocusIn.bind(this));
    }
  }
}
if (!customElements.get('header-menu')) {
  customElements.define('header-menu', HeaderMenu);
}

class VideoSection extends HTMLElement {
  constructor() {
    super();
    this.thumb = this.querySelector('.video-thumbnail');
    this.video_iframe = this.querySelector('.video-has-bg iframe');
    this.init();
  }

  init() {
    if (this.video_iframe) {
      this.video_iframe.addEventListener('load', () => {
        if (this.thumb) {
          this.thumb.remove();
        }
      });
    }
  }
}
if (!customElements.get('video-section')) {
  customElements.define('video-section', VideoSection);
}

class VideoLocal extends HTMLElement {
  constructor() {
    super();
    this.init();
  }
  init() {
    setTimeout(() => {
      this.loadContent();
    }, 100);
  }

  loadContent() {
    if (!this.getAttribute('loaded')) {
      const content = document.createElement('div');
      content.appendChild(this.querySelector('template').content.firstElementChild.cloneNode(true));
      this.setAttribute('loaded', true);
      const deferredElement = this.appendChild(content.querySelector('video'));
      const alt = deferredElement.getAttribute('alt');
      const img = deferredElement.querySelector('img');
      if (alt && img) {
        img.setAttribute('alt', alt);
      }
      this.thumb = this.querySelector('.video-thumbnail');
      if (this.thumb) {
        this.thumb.remove();
      }
      if (deferredElement.nodeName == 'VIDEO' && deferredElement.getAttribute('autoplay')) {
        deferredElement.play();
      }
    }
  }
}
if (!customElements.get('video-local')) {
  customElements.define('video-local', VideoLocal);
}

class VideoLocalPlay extends VideoLocal {
  constructor() {
    super();
    this.init();
  }
  init() {
    const poster = this.querySelector('.video-thumbnail');
    if (!poster) return;
    poster.addEventListener('click', this.loadContent.bind(this));
  }
}
if (!customElements.get('video-local-play')) {
  customElements.define('video-local-play', VideoLocalPlay);
}

class ShopableImage extends HTMLElement {
  constructor() {
    super();
    this.connectedCallback();
    this.dot = this.querySelectorAll('.shopable-image-hotspot');
    this.active = 1;
    this.type = this.dataset?.type;
    if (this.dot.length < 1) return;
    const _this = this;
    if (this.type === 'default') {
      this.dot.forEach((e) => {
        e.addEventListener('click', _this.onClickPopup.bind(_this), false);
        e.addEventListener(
          'keypress',
          function (event) {
            if (event.key === 'Enter') {
              this.onClickPopup.bind(this)(event);
            }
          }.bind(this),
          false
        );
      });
    } else {
      this.dot.forEach((e) => {
        e.addEventListener('click', _this.onClickSlide.bind(_this), false);
        e.addEventListener(
          'keypress',
          function (event) {
            if (event.key === 'Enter') {
              this.onClickSlide.bind(this)(event);
            }
          }.bind(this),
          false
        );
      });
    }
    this.onResize();
  }
  initFunction() {
    this.onBodyClick();
  }
  connectedCallback() {
    const __this = this;
    const handleIntersection = (entries, observer) => {
      if (!entries[0].isIntersecting) return;
      observer.unobserve(this);
      __this.initFunction();
    };

    new IntersectionObserver(handleIntersection.bind(this), {
      rootMargin: '0px 0px -400px 0px',
    }).observe(this);
  }
  onClickPopup(e) {
    if (window.innerWidth >= 768) {
      const target = e.currentTarget;
      if (target && target.closest('.shopable-image-item')) {
        const position = target.closest('.shopable-image-item').dataset.productPosition;
        if (position) {
          if (this.active === position) {
            target.closest('.shopable-image-item').classList.toggle('active');
          } else {
            this.removeActive();
            target.closest('.shopable-image-item').classList.add('active');
          }
          this.active = position;
        }
      }
    }
  }
  onClickSlide(e) {
    const target = e.currentTarget;
    if (target && target.closest('.shopable-image-item')) {
      const position = target.closest('.shopable-image-item').dataset.productPosition;
      if (position) {
        if (this.active === position) {
          target.closest('.shopable-image-item').classList.toggle('active');
        } else {
          this.removeActive();
          target.closest('.shopable-image-item').classList.add('active');
        }
        this.active = position;
        if (this.querySelector('slide-section')) {
          this.querySelector('slide-section').gotoFunction(position - 1);
          this.querySelector('slide-section').addClass(position, 'item-highlight');
        }
      }
    }
  }
  onBodyClick() {
    const _this = this;
    document.body.addEventListener('click', (e) => {
      const target = e.target;
      if (!target.closest('.shopable-image-item')) {
        _this.removeActive();
      }
    });
  }
  removeActive() {
    if (this.dot.length < 1) return;
    this.dot.forEach((e) => {
      if (!e.closest('.shopable-image-item')) return;
      e.closest('.shopable-image-item').classList.remove('active');
    });
  }
  onResize() {
    if (window.innerWidth < 1200) {
      this.removeActive();
    }
  }
}

if (!customElements.get('shopable-image')) {
  customElements.define('shopable-image', ShopableImage);
}

if (!customElements.get('media-gallery')) {
  customElements.define(
    'media-gallery',
    class MediaGallery extends HTMLElement {
      constructor() {
        super();
        this.elements = {
          viewer: this.querySelector('[id^="GalleryViewer"]'),
        };
        const mobileSlideStatus = this.dataset.mobiSlideStatus;
        if (!mobileSlideStatus) {
          this.onResize();
        }
      }

      setActiveMedia(mediaId, prepend) {
        const activeMedia = this.elements.viewer.querySelector(`[data-media-id="${mediaId}"]`);
        this.elements.viewer.querySelectorAll('[data-media-id]').forEach((element) => {
          element.classList.remove('is-active');
        });
        activeMedia.classList.add('is-active');

        if (prepend) {
          activeMedia.parentElement.prepend(activeMedia);
          if (this.elements.thumbnails) {
            const activeThumbnail = this.elements.thumbnails.querySelector(
              `[data-target="${mediaId}"]`
            );
            activeThumbnail.parentElement.prepend(activeThumbnail);
          }
          // if (this.elements.viewer.slider) this.elements.viewer.resetPages();
        }

        window.setTimeout(() => {
          if (this.elements.thumbnails) {
            activeMedia.parentElement.scrollTo({ left: activeMedia.offsetLeft });
          }
          if (!this.elements.thumbnails || this.dataset.desktopLayout === 'stacked') {
            // activeMedia.scrollIntoView({behavior: 'smooth'});
          }
        });
      }
      onResize() {
        const _this = this;
        let windowWidth = window.innerWidth;
        let isMobile = windowWidth <= 767;
        window.addEventListener('resize', function () {
          const newWindowWidth = window.innerWidth;
          const newIsMobile = newWindowWidth <= 767;
          if (newIsMobile !== isMobile) {
            windowWidth = newWindowWidth;
            isMobile = newIsMobile;
            _this.actionResize(isMobile);
          }
        });
        _this.actionResize(isMobile);
      }

      actionResize(isMobile) {
        const sl_thumb = this.querySelector('.slide-thumbnail');
        if (sl_thumb) {
          if (isMobile) {
            sl_thumb.classList.add('d-none');
          } else {
            sl_thumb.classList.remove('d-none');
          }
        }
      }
    }
  );
}

if (!customElements.get('button-search')) {
  customElements.define(
    'button-search',
    class Search extends ToggleNav {
      constructor() {
        super();
      }
      onClick(e) {
        e.preventDefault();
        const items = this.dataset?.items;
        this.onMainClick(items);
        if (!this.closest('action-search')) return;
        if (!this.closest('action-search').querySelector('input[type="search"]')) return;
        setTimeout(() => {
          this.closest('action-search').querySelector('input[type="search"]').focus();
        }, 100);
      }
    }
  );
}
